import { useContext, useEffect, useState } from 'react';
import useGetUsersCollections from '../../hooks/useGetUsersCollections';
import { getCdnUrl } from '../../lib/cdnUrl';
import FilterCategoryItem from '../categories/filterCategoryItem';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import Link from 'next/link';
import { normalizeEther } from '../../helpers/digits';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { CheckInput } from '../checkInput';
import { useAccount, useNetwork, useSignMessage, useSignTypedData } from 'wagmi';
import useGetUser from '../../hooks/useGetUser';
import { hashMessage } from 'ethers/lib/utils.js';
import { DataRefreshContext } from '../refreshContext';
import { toast } from 'react-hot-toast';
import { getAddress } from '@ethersproject/address';
import editUserProfile from '../../services/editUserProfile';
import { Tooltip } from '@mui/material';
import useGetEstimatedWalletValueByCollection from '../../hooks/useGetEstimatedWalletValueByCollection';
import { ChainIcon } from '../Icon';
import { getTokenName } from '../../helpers/getChainName';
import { RiErrorWarningLine } from 'react-icons/ri';
import useGetUserStakeInfo from '../../hooks/useGetUserStakeInfo';
import { dynamicDomain, editProfileMessage, sortMessage, types } from '../../lib/sign';

export const EditCollectionGroup = ({ walletAddress, onChange, collectionsRaw }) => {
  const [collections, setCollections] = useState(null);
  const [openCollection, setOpenCollection] = useState('');

  useEffect(() => {
    setCollections(collectionsRaw || null);
  }, [collectionsRaw]);
  const SortableItem = SortableElement(({ value }) => {
    const { collectionmetrics, _count, profilePhotoPath, chain, address, name } = value;
    return (
      <li className='list-none [&>*]:list-none'>
        {
          <div className='mb-4 rounded-lg bg-jacarta-100 p-3 text-jacarta-600 dark:bg-jacarta-600 dark:text-white'>
            <div className='w-[4rem]'>
              <Link href={`/collection/${chain}/${address}`}>
                <a>
                  <ImageFixedAO
                    image={getCdnUrl(profilePhotoPath)}
                    bottomRounded
                    moonpetsAccessory={true}
                  />
                </a>
              </Link>
            </div>
            <div className='flex'>
              <div>
                <Link href={`/collection/${chain}/${address}`}>
                  <a>
                    {/* <h3 className="text-2xl text-accent-light dark:text-accent-dark font-bold hover:text-accent-dark dark:hover:text-accent-light transition-colors duration-200"> */}
                    <h3
                      className={
                        'mt-4 block font-display text-2xl text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent'
                      }
                    >
                      {name}
                    </h3>
                  </a>
                </Link>
                <p>
                  <span className='text-bold font-bold'>Floor Price: </span>{' '}
                  {collectionmetrics?.floorPrice || '-'}
                </p>
                <p>
                  <span className='text-bold font-bold'>Your Items - Total Item Count:</span>{' '}
                  {_count.items} / {collectionmetrics.itemCount}
                </p>
              </div>
              <button
                type='button'
                onClick={() => setOpenCollection(address == openCollection ? '' : address)}
                className={
                  'mt-[-0.4rem] ml-auto h-fit rounded-lg border p-2 font-display text-sm font-semibold md:-mt-2'
                }
              >
                {address == openCollection ? 'Hide Items' : 'Show Items'}
              </button>
            </div>
            {address == openCollection && (
              <div>
                <FilterCategoryItem
                  propertiesDisabled
                  disableFilters
                  owner={walletAddress}
                  horizontalFilterEnabled
                  collection={address}
                />
              </div>
            )}
          </div>
        }
      </li>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul className='mt-5'>
        {items.map((value, index) => (
          <SortableItem key={`item-${value}`} index={index} value={value} />
        ))}
      </ul>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newOrder = arrayMove(collections, oldIndex, newIndex);
    if (onChange) onChange(newOrder.map((c) => c.address));
    setCollections(newOrder);
  };

  return <div>{collections && <SortableList items={collections} onSortEnd={onSortEnd} />}</div>;
};

export const CollectionGroupStable = ({ walletAddress, collection, stakeInfo, hideItems }) => {
  const [openCollection, setOpenCollection] = useState('');
  const { collectionmetrics, _count } = collection;
  const { data: estimatedValue } = useGetEstimatedWalletValueByCollection(
    walletAddress,
    collectionmetrics?.floorPrice ? collection?.address : null,
  );

  return (
    <div
      key={`cg${collection.address}`}
      className='mt-5 rounded-lg bg-jacarta-100 p-3 text-jacarta-600 dark:bg-jacarta-600 dark:text-white'
    >
      <div
        className={[
          'flex flex-col sm:flex-row',
          'justify-center sm:justify-between',
          'items-center sm:items-start',
          'space-x-3 md:mb-0',
          'cursor-pointer select-none',
          collection.address == openCollection ? 'mb-4' : '',
        ].join(' ')}
        onClick={(e) => {
          const id = e.target.id;
          if ((id && id === 'viewCollection') || hideItems) return;
          setOpenCollection(collection.address == openCollection ? '' : collection.address);
        }}
        data-testid='owned-collections'
      >
        <div className='flex flex-col items-center sm:flex-row sm:items-start sm:space-x-3'>
          <div className='flex w-[4rem] min-w-[4rem] items-center'>
            <ImageFixedAO
              image={getCdnUrl(collection.profilePhotoPath)}
              bottomRounded
              moonpetsAccessory={true}
            />
          </div>
          <div className='flex'>
            <div className='flex flex-col items-center sm:items-start'>
              {/* <h3 className="text-2xl text-accent-light dark:text-accent-dark font-bold hover:text-accent-dark dark:hover:text-accent-light transition-colors duration-200"> */}
              <h3
                className={
                  'mt-4 mb-1 block font-display text-xl text-jacarta-700 dark:text-white sm:mt-0 md:text-2xl'
                }
              >
                {collection.name}
              </h3>
              <p>
                <span className='text-bold font-bold'>Floor Price: </span>{' '}
                {collectionmetrics?.floorPrice || '-'}
              </p>
              <p>
                <span className='text-bold font-bold'>Your Items - Total Item Count:</span>{' '}
                {_count.items} / {collectionmetrics?.itemCount ?? 'NA'}{' '}
                {/* {stakeInfo?.[collection.address] && (
                  <span>
                    (<span className='text-semibold font-bold'>Stake Amount: </span>
                    {stakeInfo?.[collection.address]})
                  </span>
                )}{' '} */}
              </p>
              {stakeInfo?.[collection.address] && (
                <p className='flex items-center space-x-1.5'>
                  <span className='text-bold font-bold'>Staking on Kalmy: </span>
                  <span>{stakeInfo?.[collection.address]?.amount}</span>
                  <Tooltip
                    title={
                      'Stake data is updated every 2 hours, so the amount shown here may not be accurate'
                    }
                    className=''
                    enterTouchDelay={0}
                  >
                    <span className='font-display text-sm font-semibold text-accent hover:text-accent-light dark:text-white'>
                      <RiErrorWarningLine size={16} />
                    </span>
                  </Tooltip>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center space-y-2 sm:min-w-fit'>
          <Link href={`/collection/${collection.chain}/${collection.pathName}`}>
            <a
              id={'viewCollection'}
              className={
                'h-fit font-display text-sm font-semibold text-accent hover:text-accent-light'
              }
              target='_blank'
            >
              {/* {c.address == openCollection ? 'Hide Items' : 'Show Items'} */}
              View Collection
            </a>
          </Link>
          {estimatedValue && !hideItems ? (
            <div className='flex flex-row items-center space-x-1'>
              <p className='text-xs'>Estimated Value:</p>
              <span className='flex items-center'>
                <ChainIcon
                  name={collection?.chain}
                  tooltip={getTokenName(collection?.chain)}
                  width={12}
                />
                <p className='ml-0.5 text-xs'>{Number(estimatedValue.toFixed(4))}</p>
              </span>
              <Tooltip
                title={
                  'Estimated value is calculated by multiplying the floor price of the collection by the number of items you own in the collection'
                }
                className='hidden sm:block'
              >
                <span className='font-display text-sm font-semibold text-accent hover:text-accent-light dark:text-white'>
                  <RiErrorWarningLine size={14} />
                </span>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
      {collection.address == openCollection && (
        <div>
          <FilterCategoryItem
            propertiesDisabled
            disableFilters
            owner={walletAddress}
            horizontalFilterEnabled
            collection={collection.address}
          />
        </div>
      )}
    </div>
  );
};

export const CollectionGroup = ({ walletAddress }) => {
  const { address, isConnected } = useAccount();
  const { refreshHooks } = useContext(DataRefreshContext);
  const { data: collections } = useGetUsersCollections(walletAddress);
  const [editEnabled, setEditEnabled] = useState(false);
  const [order, setOrder] = useState([]);
  const [orderedList, setOrderedList] = useState([]);
  const userData = useGetUser(address).data;
  // const { signMessageAsync } = useSignMessage();
  const { data: stakeInfo } = useGetUserStakeInfo(walletAddress);
  const { chain } = useNetwork();
  const collectionsWithoutItems = Object.keys(stakeInfo || {}).reduce((acc, key) => {
    if (!collections?.some((c) => c.address == key)) {
      acc.push({ ...stakeInfo[key]?.collection, _count: { items: 0 } });
    }
    return acc;
  }, []);

  const { signTypedDataAsync } = useSignTypedData({
    domain: dynamicDomain(chain?.hexId),
    types: types,
    primaryType: 'EditProfile',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const inputs = JSON.stringify({
      collectionOrder: order,
      walletAddress: getAddress(address),
    });

    // const hashedMessage = hashMessage(inputs);
    const signature = await signTypedDataAsync({ message: { EditProfile: editProfileMessage } });
    const response = await editUserProfile(inputs, signature, chain?.hexId);
    if (response) {
      toast.success('Profile updated successfully');
      refreshHooks();
    }
    setEditEnabled(false);
  };

  useEffect(() => {
    if (!collections) return;
    if (!userData) return setOrderedList(collections);
    const dborder = userData?.collectionOrder || [];
    const newOrder = [];
    dborder.map((address) => {
      collections &&
        collections.map((c) => {
          if (c.address == address) newOrder.push(c);
        });
    });
    collections &&
      collections?.map((c) => {
        if (!dborder.includes(c.address)) newOrder.push(c);
      });
    setOrderedList(newOrder);
  }, [collections, userData]);

  return (
    <div>
      {address == walletAddress &&
        (editEnabled ? (
          <div className='mb-5 flex w-full md:justify-between'>
            <div className='relative mt-5 ml-auto flex items-center justify-center text-accent'>
              {/* <Link href={`/home/${address}`}> */}
              <span
                onClick={handleSubmit}
                className='flex cursor-pointer items-center space-x-1 font-bold dark:text-white'
              >
                Save
              </span>
              <span
                onClick={() => setEditEnabled(false)}
                className='ml-5 flex cursor-pointer items-center space-x-1 font-bold  text-red'
              >
                Cancel
              </span>
            </div>
          </div>
        ) : (
          <div className='mb-5 flex w-full justify-between'>
            <div className='relative mt-5 ml-auto flex items-center justify-center text-accent'>
              {/* <Link href={`/home/${address}`}> */}
              <Tooltip
                title={
                  <div className='text-center'>
                    <p>Drag and drop to reorder your collections</p>
                  </div>
                }
              >
                <span
                  onClick={(e) => {
                    setEditEnabled(true);
                  }}
                  className='flex cursor-pointer items-center space-x-1 font-bold dark:text-white'
                >
                  Sort Your Collections
                </span>
              </Tooltip>
              {/* </Link> */}
            </div>
          </div>
        ))}
      {editEnabled ? (
        <EditCollectionGroup
          walletAddress={walletAddress}
          onChange={setOrder}
          collectionsRaw={orderedList}
        />
      ) : (
        <>
          {orderedList?.map((c) => {
            return (
              <CollectionGroupStable
                walletAddress={walletAddress}
                collection={c}
                key={`collection-${c.address}`}
                stakeInfo={stakeInfo}
              />
            );
          })}
          {collectionsWithoutItems?.map((c) => {
            return (
              <CollectionGroupStable
                walletAddress={walletAddress}
                collection={c}
                key={`collection-${c.address}`}
                stakeInfo={stakeInfo}
                hideItems
              />
            );
          })}
        </>
      )}
    </div>
  );
};
