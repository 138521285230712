import React, { useEffect, useState } from 'react';
import { OnEnterScreen } from '../onEnterScreen';
import { ActivitySkeleton } from '../Skelaton';
import { Tooltip } from '@mui/material';
import useGetOrders from '../../hooks/useGetOrders';
import User_orders_item from './User_orders_item';
import { useNetwork, usePublicClient, useWalletClient } from 'wagmi';
import { getContract } from '@wagmi/core';
import abi from '../../helpers/abi';
import { useGetChain } from '../../hooks/useGetChain';
import { cancelAllOrders } from '../../helpers/blockchainOperations';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { errorToast } from '../toast';
import { defaultChainId } from '../Web3WalletProvider';

const User_orders = ({
  address,
  collectionAddress,
  walletAddress,
  itemId,
  received,
  listingsDisabled,
}) => {
  const defaultFilter = listingsDisabled ? 'bid' : 'listing';
  const [filterVal, setFilterVal] = useState(defaultFilter);
  const [shouldLoad, setShouldLoad] = useState({});
  const [page, setPage] = useState(1);
  const [lastData, setLastData] = useState(null);
  const filterData = listingsDisabled ? ['bid'] : ['listing', 'bid'];
  const signer = useWalletClient();
  const publicClient = usePublicClient();
  const { chain } = useNetwork();
  const defaultText = 'Cancel All Bids';
  const [buttonText, setButtonText] = useState(defaultText);
  const [disabled, setDisabled] = useState(true);

  const { isChainActive } = useGetChain(chain?.id);
  const { fetchWithCatchTxError } = useCatchTxError();

  const { data: swr } = useGetOrders({
    address,
    chainId: chain?.hexId ?? defaultChainId,
    page,
    limit: 50,
    sortBy: 'updatedAt:desc',
    eventType: filterVal,
    received,
  });

  const { results: allOrders, page: lastPage, totalPages, isError } = swr || {};
  const columnHeaders = [
    'Type',
    'Item',
    'Price',
    ...(received ? ['From'] : ['To']),
    'Date',
    'Expires',
  ];

  const marketplaceContract = getContract({
    address: chain?.marketplace,
    abi,
    publicClient,
    walletClient: signer.data,
  });

  const handleCancelAllBids = async () => {
    if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
    const bulkBids = [];
    const singleBids = [];

    lastData?.forEach((item) => {
      if (item?.bulkSignature !== undefined && item?.bulkSignature !== null) {
        item.signature = item.bulkSignature;
        bulkBids.push(item);
      } else if (
        item?.bulkSignature === undefined ||
        (item?.bulkSignature === null && item?.signature)
      ) {
        singleBids.push(item);
      }
    });

    try {
      setButtonText('Canceling the bids...');

      const contractFn = await cancelAllOrders(singleBids, bulkBids, chain);

      await fetchWithCatchTxError({
        callTx: contractFn,
        chainId: chain.hexId ?? defaultChainId,
        toastMessage: 'Offer cancelled!',
      });
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setButtonText(defaultText);
    }
  };

  useEffect(() => {
    const filteredOrders =
      allOrders &&
      allOrders?.filter((order) => {
        return order.itemId !== '0' || order?.globalBidAmount === 0;
      });
    if (filteredOrders && lastData && lastPage !== 1) {
      return setLastData([...lastData, ...filteredOrders]);
    }
    if (filteredOrders && lastPage == 1) {
      setLastData(filteredOrders);
    }
    if (filteredOrders?.length > 0) setDisabled(false);
  }, [allOrders]);
  useEffect(() => {
    if (lastPage < totalPages) setPage(lastPage + 1);
  }, [shouldLoad]);
  useEffect(() => {
    setPage(1);
  }, [filterVal]);
  return (
    <>
      {/* <!-- Order Tab --> */}
      <div className='tab-pane fade'>
        {/* <!-- Records / Filter --> */}
        <div className='flex flex-col'>
          {!received && (
            <div className=''>
              <h3 className='mb-4 font-display font-semibold text-jacarta-500 dark:text-white'>
                Filters
              </h3>
              <div className='flex w-full justify-between'>
                <div className='flex flex-wrap'>
                  {filterData.map((eventType) => {
                    return (
                      <button
                        data-testid='orders-listings'
                        className={
                          filterVal === eventType
                            ? 'rounded-xl group mr-2.5 mb-2.5 inline-flex items-center border border-jacarta-100 border-transparent bg-accent px-4 py-3 text-white dark:border-jacarta-600 dark:border-transparent'
                            : 'rounded-xl group mr-2.5 mb-2.5 inline-flex items-center border border-jacarta-100 bg-white px-4 py-3 hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:hover:border-transparent dark:hover:bg-accent'
                        }
                        key={eventType}
                        onClick={() => {
                          filterVal === eventType
                            ? setFilterVal(defaultFilter)
                            : setFilterVal(eventType);
                        }}
                      >
                        <Tooltip title={eventType}>
                          <svg
                            className={
                              filterVal === eventType
                                ? 'icon mr-2 h-4 w-4 fill-white'
                                : 'icon mr-2 h-4 w-4 fill-jacarta-700 group-hover:fill-white dark:fill-white'
                            }
                          >
                            <use xlinkHref={`/icons.svg#icon-${eventType}`} />
                          </svg>
                        </Tooltip>
                        <span className='text-2xs font-medium capitalize'>{eventType}</span>
                      </button>
                    );
                  })}
                </div>
                {walletAddress === address && filterVal == 'bid' ? (
                  <button
                    className={`rounded-xl group mr-2.5 mb-2.5 inline-flex items-center border border-jacarta-100 border-transparent bg-accent px-4 py-2 text-white dark:border-jacarta-600 dark:border-transparent ${
                      disabled ? 'bg-opacity-50' : ''
                    }`}
                    onClick={handleCancelAllBids}
                    disabled={disabled}
                  >
                    {buttonText}
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
          {/* <!-- Records --> */}
          <div className='scrollbar-custom w-full overflow-x-auto'>
            <div
              role='table'
              className='w-full min-w-[736px] border border-jacarta-100 bg-white text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white lg:rounded-2lg'
            >
              <div className='flex rounded-t-lg bg-jacarta-50 dark:bg-jacarta-600' role='row'>
                {columnHeaders.map((name) => (
                  <div
                    key={name + 'he'}
                    className='py-3 px-4'
                    role='columnheader'
                    style={{
                      width: 95 / columnHeaders.length + '%',
                    }}
                  >
                    <span
                      className='flex w-full overflow-hidden text-ellipsis whitespace-nowrap text-jacarta-700 dark:text-jacarta-100' // justify-center
                    >
                      {name}
                    </span>
                  </div>
                ))}
              </div>
              {!lastData && !allOrders && <ActivitySkeleton count={10} />}
              {lastData?.map((order, i) => {
                return (
                  <User_orders_item order={order} key={i} received={received} address={address} />
                );
              })}
              {!allOrders && !isError ? <ActivitySkeleton count={10} /> : null}
              {allOrders && allOrders.length === 0 && !isError && (
                <div className='flex items-center justify-center py-4'>
                  <span className='text-sm font-semibold text-jacarta-700 dark:text-jacarta-100'>
                    No orders found!
                  </span>
                </div>
              )}
            </div>
            {page < totalPages && (
              <OnEnterScreen
                onVisible={() => {
                  setShouldLoad({});
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default User_orders;
