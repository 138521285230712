import { useEffect, useState } from 'react';
import { ChainIcon } from '../Icon';
import getChainName from '../../helpers/getChainName';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import { ethers } from 'ethers';
import { onlyNumber } from '../../lib/keyevent';
import { getItemImagePath } from '../../helpers/path';

export const BulkToolSelectItem = ({
  item,
  selectedItems,
  setSelectedItems,
  samePriceValue,
  useSamePrice,
  approvedCollections,
  isAnyGlobalBid,
}) => {
  const [price, setPrice] = useState(
    item?.orders?.length > 0 ? item?.orders[0].formattedPrice : undefined,
  );
  const [useInputValue, setUseInputValue] = useState(false);
  const [image, setImage] = useState(null);
  const ordersForSort = [...(item?.orders || [])];
  const sortedOrders = ordersForSort?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const order = sortedOrders[0];
  const collection = item?.collection;
  const toggleItem = (item) => {
    if (selectedItems?.find((i) => i.id === item.id)) {
      setSelectedItems(selectedItems?.filter((i) => i.id !== item.id));
    } else {
      if (approvedCollections[item?.collectionAddress]) setSelectedItems([...selectedItems, item]);
    }
  };

  const showGlobalBidWarning = price != item.price;

  const isEnabled = approvedCollections[item?.collectionAddress];

  const handleEThAmount = (e) => {
    e.preventDefault();
    setUseInputValue(true);

    const newAmount = e?.target?.value.replace(',', '.');
    const zeroCounts = newAmount.split('.');

    if (zeroCounts?.length >= 1 && zeroCounts[1]?.length > 4) return;
    setPrice(newAmount);
  };

  useEffect(() => {
    //console.log('id:', item.itemId, 'price:', item.price);
    if (selectedItems?.find((i) => i.id === item.id)) {
      let newPrice = price;
      if (samePriceValue && useSamePrice && !useInputValue) {
        newPrice = samePriceValue;
      }
      setPrice(newPrice);
      setUseInputValue(false);

      setSelectedItems((p) => {
        return p.map((i) => (i.id === item.id ? { ...i, price: newPrice } : i));
      });
    }
  }, [price, selectedItems?.length]);

  useEffect(() => {
    if (samePriceValue === '' && useSamePrice) {
      setPrice(item.price);
      return;
    }
    if (!samePriceValue || !useSamePrice /* || !selectedItems?.find((i) => i.id === item.id) */)
      return;
    if (selectedItems?.find((i) => i.id === item.id)) {
      setPrice(samePriceValue);
    }
  }, [samePriceValue, useSamePrice]);

  useEffect(() => {
    setImage(getItemImagePath(item, 'thumbnail'));
  }, [item.imageFtpPath]);

  return (
    <div className={`border-t border-jacarta-100 ${isEnabled ? '' : 'opacity-25'}`}>
      <div>
        {price &&
          showGlobalBidWarning &&
          isAnyGlobalBid &&
          Object.keys(isAnyGlobalBid).length > 0 &&
          ethers.utils.parseEther('' + price).lte(isAnyGlobalBid?.price) && (
            <div className='alert-error mt-2 w-full rounded-lg p-2'>
              Your price is below or equal to the highest global bid
            </div>
          )}
      </div>

      <div className='my-2 flex justify-between py-2.5 dark:border-jacarta-600 md:gap-5'>
        <div className='flex flex-1 items-center'>
          <input
            type='checkbox'
            checked={
              approvedCollections[item?.collectionAddress] &&
              !!selectedItems?.find((i) => i.id === item.id)
            }
            onChange={() => toggleItem(item)}
            disabled={!isEnabled}
            className={'mr-6 h-6 w-6 rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600 '.concat(
              isEnabled ? 'cursor-pointer' : 'cursor-not-allowed',
            )}
            data-testid='bulkToolItem'
          />
          <div className='mr-3 h-12 w-12 rounded '>
            <ImageFixedAO image={image} alt={collection?.address} bottomRounded />
          </div>
          <div className='inline-flex flex-col items-start justify-start gap-1 md:gap-3'>
            <div className='h-[18px] max-w-[160px] overflow-hidden text-ellipsis whitespace-nowrap text-base font-bold sm:max-w-[240px]'>
              {item?.name}
            </div>
            <div className='h-[18px] max-w-[160px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-normal sm:max-w-[240px] md:max-w-[240px]'>
              {item?.collection?.name}
            </div>
            <div className='flex gap-2 text-sm font-normal md:hidden'>
              <div className='inline-flex items-center justify-start gap-3'>
                <div className='text-sm font-normal'>Floor</div>
              </div>
              <div className='inline-flex items-center justify-start gap-1'>
                <ChainIcon name={collection?.chain} tooltip={getChainName(collection?.chain)} />
                <div className='flex items-center justify-center gap-1.5'>
                  <div className='text-base font-bold'>
                    {collection?.collectionmetrics?.floorPrice}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden w-full max-w-[80px] flex-col justify-start gap-1.5 md:inline-flex'>
          <div className='inline-flex items-center justify-start gap-3'>
            <div className='text-sm font-medium'>Floor</div>
          </div>
          <div className='inline-flex items-center justify-start gap-1'>
            {/*  // <div className="text-sm font-normal">{}</div> */}
            <ChainIcon name={collection?.chain} tooltip={getChainName(collection?.chain)} />
            <div className='flex items-center justify-center'>
              <div className='text-base font-bold'>{collection?.collectionmetrics?.floorPrice}</div>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-end pt-0.5'>
          <ChainIcon
            className={'mr-2'}
            name={collection?.chain}
            tooltip={getChainName(collection?.chain)}
          />
          <input
            type='text'
            placeholder='0'
            value={price}
            onChange={(e) => {
              handleEThAmount(e);
            }}
            onKeyDown={onlyNumber}
            onWheel={(e) => e.target.blur()}
            disabled={!approvedCollections[item?.collectionAddress]}
            className='rounded-xl BulkToolPriceInput h-10 w-[80px] pl-3 focus:ring-accent dark:bg-jacarta-700  dark:text-white dark:focus:ring-accent'
            data-testid='bulkToolItemPrice'
          />
        </div>
      </div>
    </div>
  );
};
