import { useEffect, useState } from 'react';
import useGetUsersCollections from '../../hooks/useGetUsersCollections';
import { useAccount, useWalletClient } from 'wagmi';
import { BulkToolSelectItem } from './BulkToolSelectItem';
import useGetOwnedNfts from '../../hooks/useGetOwnedNfts';
import { FaSearch } from 'react-icons/fa';
import { BulkToolChains } from './BulkToolChains';
import { BulkToolCollections } from './BulkToolCollections';
import { usePagination } from '../../hooks/usePagination';
import BulkToolPagination from './BulkToolPagination';
import Status from './statusButtons';
import { useDispatch } from 'react-redux';
import { setModal } from '../../redux/counterSlice';
import { constants } from '../../helpers/constants';
import DropdownDynamic from '../dropdown/dropdown_dynamic';
import useGetBulkGlobalBids from '../../hooks/bulktool/useGetBulkGlobalBids';
import { onlyNumber } from '../../lib/keyevent';

export const BulkTool = () => {
  const [status, setStatus] = useState('notOnSale');
  const { address: walletAddress } = useAccount();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [transferAndSell, setTransferAndSell] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [sort, setSort] = useState('recent');
  const dispatch = useDispatch();
  const { data: currentWallet } = useWalletClient();
  const [sellButtonDisabled, setSellButtonDisabled] = useState(true);
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(true);
  const [approvedCollections, setApprovedCollections] = useState({});
  const [selectedChainIds, setSelectedChainIds] = useState([]);
  const [useSamePrice, setUseSamePrice] = useState(false);
  const [samePriceValue, setSamePriceValue] = useState();
  const { data: collections } = useGetUsersCollections(walletAddress, selectedChainIds);
  const [completeSell, setCompleteSell] = useState(false);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [cancelButtonText, setCancelButtonText] = useState('Cancel');
  const [sellButtonText, setSellButtonText] = useState('Sell');
  const collectionAddresses = collections?.map((i) => i.address) || [];
  const supportedChains = constants.helpers.supportedChains;
  const [controlDisabled, setControlDisabled] = useState(
    filteredCollections ? filteredCollections.length == 0 : true,
  );

  const { data: globalBids, isLoading: isGlobalBidsLoading } =
    useGetBulkGlobalBids(collectionAddresses);

  const {
    data: items,
    totalCount,
    totalPages,
    page,
    changePage,
  } = usePagination({
    useFetch: useGetOwnedNfts,
    collectionAddresses,
    walletAddress,
    defaultLimit: 100,
    defaultPage: 0,
    buy_now: status === 'onSale' /*  buy_now: status === 'onSale' ? true : false, */,
    sort,
    selectedCollection,
    chainId: selectedChainIds,
  });

  const sortSelectItems = [
    {
      id: 'recent',
      text: 'Recent',
    },
    {
      id: 'tokenId',
      text: 'Token ID',
    },
  ];

  const approvedCollectionAddresses = collectionAddresses.filter(
    (address) => approvedCollections[address],
  );

  useEffect(() => {
    resetSelections();

    if (status == 'onSale') {
      setSellButtonText('Cancel');
    } else {
      setSellButtonText('Sell');
    }
  }, [status]);

  useEffect(() => {
    if (collections?.length > 0) {
      const filteredCollection = collections.filter((collection) => {
        const textMatch =
          !filterText || collection.name.toLowerCase().startsWith(filterText.toLowerCase());
        const chainIdMatch =
          selectedChainIds.length === 0 || selectedChainIds.includes(collection.chain);

        return textMatch && chainIdMatch;
      });

      setFilteredCollections(filteredCollection);
    } else {
      setFilteredCollections([]);
    }
  }, [collections, selectedChainIds, filterText]);

  const goToPage = (p) => {
    if (p >= 0 && p < totalPages) {
      changePage(p);
      setSelectedItems([]);
      setSelectAll(false);
      setUseSamePrice(false);
      setSamePriceValue('');
    }
  };

  const toggleSelectAll = () => {
    if (!selectAll && items) {
      const selectedItems = items.filter((item) =>
        approvedCollectionAddresses.includes(item.collectionAddress),
      );
      setSelectedItems(selectedItems);
    } else {
      setSelectedItems([]);
    }
    setSelectAll(!selectAll);
  };

  const handleSamePriceChange = (e) => {
    setSamePriceValue(e.target.value);
  };

  const handleSortingChange = (e) => {
    setSort(e.id);
    resetSelections();
  };

  const handleSell = () => {
    dispatch(
      setModal({
        key: 'bulkSellModal',
        data: {
          nfts: selectedItems,
          isUpdateCancelOrder: false,
          chain: selectedItems[0].collection.chain,
          setCompleteSell: setCompleteSell,
        },
      }),
    );
  };

  const handleBulkCancel = () => {
    dispatch(
      setModal({
        key: 'bulkCancelModal',
        data: {
          selectedItems,
          chain: selectedItems[0].collection.chain,
        },
      }),
    );
  };

  const handleTransfer = () => {
    dispatch(
      setModal({
        key: 'transferModal',
        data: {
          ...selectedItems,
          useBatchTransfer: true,
          chain: selectedItems[0].collection.chain,
        },
      }),
    );
  };

  const handleUpdataPrice = async () => {
    dispatch(
      setModal({
        key: 'bulkUpdateSellModal',
        data: {
          nfts: selectedItems,
          isUpdateBulkOrder: true,
          chain: selectedItems[0].collection.chain,
        },
      }),
    );
  };

  const resetSelections = () => {
    setSelectAll(false);
    setUseSamePrice(false);
    setSamePriceValue('');
    setSelectedItems([]);
    setSellButtonDisabled(true);
    setTransferAndSell(true);
    setUpdateButtonDisabled(true);
    setCancelButtonDisabled(true);
    changePage(0);
  };

  useEffect(() => {
    if (completeSell) {
      resetSelections();
    }
  }, [completeSell]);

  useEffect(() => {
    const selectedItemWithDifferentChainId = selectedItems?.find((item, index, array) => {
      return item?.collection?.chain !== array[0].collection?.chain;
    });

    const priceLenght = selectedItems?.filter(
      (f) => f.price != null && f.price != '0' && f.price != '' && f.price > 0,
    );

    const _sellButtonDisabled =
      selectedItems?.length === 0 ? true : selectedItems?.length !== priceLenght?.length;

    const _updateButtonDisabled =
      selectedItems?.length === 0 ? true : selectedItems?.length !== priceLenght?.length;

    console.log('.....', selectedItems?.length, priceLenght?.length);

    setSellButtonDisabled(_sellButtonDisabled || selectedItemWithDifferentChainId);
    setUpdateButtonDisabled(_updateButtonDisabled || selectedItemWithDifferentChainId);
    setCancelButtonDisabled(selectedItems?.length <= 0 || selectedItemWithDifferentChainId);

    if (!items || selectedItems?.length === 0) return;

    setTransferAndSell(selectedItemWithDifferentChainId);

    const approvedItems = items.filter((item) =>
      approvedCollectionAddresses.includes(item.collectionAddress),
    );

    if (selectedItems?.length === approvedItems?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedItems, samePriceValue]);
  useEffect(() => {
    setControlDisabled(selectedCollection.length < 1);
    resetSelections();
  }, [selectedCollection]);

  useEffect(() => {
    const selectedList = Object.entries(approvedCollections)
      .filter(([_, v]) => !!v)
      .map(([k, _]) => k);

    setSelectedCollection((s) => [...s, ...selectedList]);

    if (typeof approvedCollections === 'object') {
      const approved = Object.values(approvedCollections).filter((a) => !!a).length > 0;

      setControlDisabled(approved);
    }
  }, [approvedCollections]);

  return (
    <div className='flex min-w-[400px] flex-col gap-8 border border-jacarta-100 pb-4 pt-4 dark:border-jacarta-600 lg:flex-row'>
      {/*---------------------------------1. Approval---------------------------------------*/}
      {/* <div className='grid gap-8 overflow-x-auto rounded-lg p-4 pb-4 pt-4 dark:border-jacarta-600'> */}

      <div className='w-full px-6 lg:w-[368px]'>
        {/* <div className='col-span-12 justify-center px-6 lg:col-span-4'> */}
        <div className="font-['Cal Sans'] mt-3 mb-6 text-xl font-semibold text-accent">
          1. Approval
        </div>

        <div className="-font-['Roboto'] border-b border-jacarta-100 pb-6 text-base font-normal leading-normal dark:border-jacarta-600">
          You need to approve the collection before listing. It costs a one-time gas for each
          collection.
        </div>

        <div className="font-['Cal Sans'] pt-2 text-base font-semibold">Status</div>

        <Status status={status} setStatus={setStatus} />

        <div className='flex flex-col gap-4'>
          <div className=" font-['Cal Sans'] mb-2 text-base font-semibold">Chain</div>
          {supportedChains.map((chain) => (
            <BulkToolChains
              key={`${chain.hexId}`}
              chain={chain}
              // disabled={currentWallet?.chain.hexId !== chain.hexId}
              selectedChainIds={selectedChainIds}
              setSelectedChainIds={setSelectedChainIds}
            />
          ))}

          <div className="font-['Cal Sans'] mb-2 text-base font-semibold">Collection</div>

          <div className='relative inline-flex items-center '>
            <input
              placeholder='Filter'
              type='text'
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className='rounded-xl self-strech inline-flex h-12 w-full items-center justify-between rounded-lg border border-jacarta-100 bg-white p-3 py-[0.4475rem] text-jacarta-700 placeholder-jacarta-500 focus:ring-accent dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white'
              data-testid='bulkToolFilter'
            />

            <div className='absolute right-3 bottom-1.5 h-6 w-6'>
              <div />
              <FaSearch />
            </div>
          </div>
          {filteredCollections && filteredCollections.length > 0 ? (
            <>
              {filteredCollections?.map((collection) => (
                <BulkToolCollections
                  key={collection.id}
                  currentWallet={currentWallet}
                  collection={collection}
                  setApprovedCollections={setApprovedCollections}
                  selectedCollection={selectedCollection}
                  setSelectedCollection={setSelectedCollection}
                />
              ))}
            </>
          ) : (
            <p>The collection was not found...</p>
          )}
        </div>
      </div>

      {/*--------------------------------2. Bulk Tool-----------------------------------*/}

      {/* <div className='col-span-12 border-t-2 max-lg:border-t-accent/30 lg:col-span-8 lg:border-t-0 xl:col-span-8'> */}
      <div className='w-full'>
        <div className='px-4 max-lg:mt-6 lg:px-0 lg:pr-4'>
          <div className="font-['Cal Sans'] mb-6 flex items-center justify-between text-xl font-semibold text-accent">
            <div>2. Bulk Tool</div>
            <div>
              <div className='flex gap-1'>
                <DropdownDynamic
                  items={sortSelectItems}
                  value={sortSelectItems.find((i) => i.id === sort)}
                  onSelect={handleSortingChange}
                  className={'ml-auto !w-[170px]'}
                  optionClassName={'!w-[170px] !py-3'}
                  containerClassName={'!w-[170px]'}
                />
              </div>
            </div>
          </div>
          <div className='mb-3 flex align-bottom max-md:flex-col max-md:justify-between'>
            <div className='flex justify-between gap-1 md:mr-auto md:justify-end md:gap-3 lg:gap-1 xl:gap-3'>
              <div className='flex flex-row items-center gap-2'>
                <input
                  type='checkbox'
                  onChange={toggleSelectAll}
                  checked={selectAll}
                  disabled={controlDisabled}
                  className={`h-6 w-6 cursor-pointer  rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600 ${
                    controlDisabled ? 'opacity-25' : ''
                  }`}
                  data-testid='bulkToolSelectAll'
                />

                <div className="-font-['Roboto'] text-sm font-medium md:w-full">
                  Select All
                  <span className='ml-1 text-sm text-accent'>
                    ({selectedItems?.length}/{items?.length})
                  </span>
                </div>
              </div>
              <div className='flex w-[190px] flex-row items-center justify-between gap-2'>
                <input
                  onChange={() => setUseSamePrice(!useSamePrice)}
                  checked={useSamePrice}
                  disabled={controlDisabled || selectedItems?.length <= 1}
                  type='checkbox'
                  className={`h-6 w-6 cursor-pointer rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600 ${
                    controlDisabled || selectedItems?.length <= 1 ? 'opacity-25' : ''
                  }`}
                  data-testid='bulkToolUseSamePrice'
                />
                <div className="-font-['Roboto'] min:w-[30px] max:w-[100px] h-[18px] overflow-hidden text-ellipsis text-sm font-medium">
                  {!useSamePrice || controlDisabled || selectedItems?.length <= 1
                    ? 'Use Same Price'
                    : 'USP'}
                </div>
                <input
                  type='text'
                  placeholder='0'
                  value={samePriceValue}
                  disabled={!useSamePrice || controlDisabled || selectedItems?.length <= 1}
                  onChange={handleSamePriceChange}
                  onKeyDown={onlyNumber}
                  onWheel={(e) => e.target.blur()}
                  className={`rounded-xl py-1 transition-width duration-500 focus:ring-accent dark:bg-jacarta-700  dark:text-white dark:focus:ring-accent ${
                    !useSamePrice || controlDisabled || selectedItems?.length <= 1
                      ? 'w-[40px] opacity-25'
                      : 'w-[100px]'
                  }`}
                  data-testid='bulkToolUseSamePriceAmount'
                />
              </div>
            </div>
            <div className='flex justify-between gap-1 md:ml-auto md:justify-end md:gap-3 lg:gap-1 xl:gap-3'>
              <div className='flex justify-between gap-1 max-md:mt-1 max-md:w-full'>
                <div className='flex flex-row items-center gap-2'>
                  <button
                    className={`rounded-xl -font-['Roboto'] inline-flex h-[43px] w-[85px] items-center justify-start gap-2.5 border-2 border-transparent bg-accent px-2 text-base font-semibold text-white ${
                      (selectedItems?.length && transferAndSell) || !selectedItems?.length
                        ? 'opacity-25'
                        : 'hover:bg-accent-dark'
                    }`}
                    disabled={(selectedItems?.length && transferAndSell) || !selectedItems?.length}
                    onClick={handleTransfer}
                    data-testid='bulkToolTransferButton'
                  >
                    Transfer
                  </button>
                </div>
                {status === 'onSale' ? (
                  <div className='flex gap-1'>
                    <button
                      onClick={handleUpdataPrice}
                      className={`rounded-xl -font-['Roboto'] inline-flex h-[43px] w-20 items-center justify-start gap-2.5 pl-3 text-base font-semibold text-accent dark:text-white ${
                        updateButtonDisabled
                          ? 'opacity-25'
                          : 'border-2 border-transparent bg-accent/30 hover:bg-accent/50 dark:hover:bg-accent/20'
                      } `}
                      disabled={updateButtonDisabled}
                      data-testid='bulkToolUpdateButton'
                    >
                      Update
                    </button>
                    <button
                      onClick={handleBulkCancel}
                      className={`rounded-xl -font-['Roboto'] inline-flex h-[43px] w-20 items-center justify-start gap-2.5 pr-1 pl-3 text-base font-semibold  ${
                        cancelButtonDisabled ? '  opacity-25' : ''
                      } ${'w-20 bg-jacarta-500 text-white hover:bg-jacarta-600'} `}
                      disabled={cancelButtonDisabled}
                      data-testid='bulkToolCancelButton'
                    >
                      {cancelButtonText}
                    </button>
                  </div>
                ) : (
                  <div className='flex gap-1'>
                    <button
                      onClick={handleSell}
                      className={`rounded-xl -font-['Roboto'] inline-flex h-[43px] w-20 items-center justify-center gap-2.5 border-2 border-jacarta-100 bg-white px-2 text-base font-semibold text-jacarta-700 placeholder-jacarta-500 focus:ring-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-700 dark:text-white dark:placeholder-white ${
                        sellButtonDisabled
                          ? 'opacity-25'
                          : 'hover:bg-accent-dark hover:text-jacarta-100 dark:hover:border-transparent dark:hover:bg-accent-dark'
                      } ${status == 'onSale' ? 'w-20 border-red text-red' : ''} `}
                      disabled={sellButtonDisabled}
                      data-testid='bulkToolSellButton'
                    >
                      {sellButtonText}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {items && items.length > 0 ? (
            items.map((item) => {
              // Find the globalBid that matches the collectionAddress of the current item
              const matchingGlobalBid = globalBids?.find(
                (bid) => bid.collectionAddress === item.collectionAddress,
              );

              // Check if a matching globalBid is found
              const hasMatchingGlobalBid = !!matchingGlobalBid;

              return (
                <BulkToolSelectItem
                  key={item?.id}
                  item={item}
                  selectedItems={selectedItems}
                  selectAll={selectAll}
                  setSelectedItems={setSelectedItems}
                  useSamePrice={useSamePrice}
                  samePriceValue={samePriceValue}
                  approvedCollections={approvedCollections}
                  isAnyGlobalBid={hasMatchingGlobalBid ? matchingGlobalBid : null}
                />
              );
            })
          ) : (
            <p className='mt-5 text-center'>No data yet...</p>
          )}
        </div>
        {/* Pagination  */}
        {items && items.length > 0 && (
          <BulkToolPagination
            page={page}
            goToPage={goToPage}
            totalPages={totalPages}
            totalCount={totalCount}
          />
        )}
      </div>
    </div>
  );
};
