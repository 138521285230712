import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import TabPanel from '../TabPanel';
import Activity_item from '../collectrions/Activity_item';
import useGetWalletActivities from '../../hooks/useGetWalletActivities';
import { BsBookmarkHeart } from 'react-icons/bs';
import { useGetWatchlist } from '../../hooks/useWatchlist';
import Explore_collection from '../../pages/collection/explore_collection';
import qs from 'query-string';
import { useRouter } from 'next/router';
import User_orders from '../collectrions/User_orders';
import { CollectionGroup } from '../collectrions/CollectionGroup';
import { Explore_collection_item_table } from '../collectrions/explore_collection_table';
import { useAccount } from 'wagmi';
import { LikedItems } from '../rankings/most_liked_items';
import { BulkTool } from '../bulkTool/BulkTool';
import { UniversalWrapper } from '../universalWrapper/UW';

import 'react-tabs/style/react-tabs.css';

const User_items = ({ address }) => {
  const { address: walletAddress } = useAccount();
  const router = useRouter();
  const [tab, setTab] = useState(router?.query?.active ? Number(router.query.active) : 1);

  const [activeTabId, setActiveTabId] = useState(1);

  useEffect(() => {
    if (tabs && tab) {
      setActiveTabId(tabs?.[tab - 1]?.id);
    }
  }, [tabs, tab]);
  const { data: getWalletActivities } = useGetWalletActivities(address, activeTabId === 5);
  const { data: getWatchlist } = useGetWatchlist(address, activeTabId === 7);

  const tabItem = [
    {
      id: 1,
      text: 'Owned',
      icon: 'owned',
      show: true,
      tabPanel: address && <CollectionGroup walletAddress={address} />,
    },
    {
      id: 2,
      text: 'Orders',
      icon: 'sale',
      show: true,
      tabPanel: <User_orders address={address} walletAddress={walletAddress} />,
    },
    {
      id: 3,
      text: 'Received Offers',
      icon: 'sale',
      show: walletAddress === address,
      tabPanel: (
        <User_orders address={address} walletAddress={walletAddress} received listingsDisabled />
      ),
    },
    {
      id: 4,
      text: 'Collections',
      icon: 'listing',
      show: true,
      tabPanel: (
        <div className='w-full gap-[1.875rem]'>
          {address && (
            <Explore_collection
              disableFilters
              disableTabs
              additionalFilter={{ creatorAddress: address }}
            />
          )}
        </div>
      ),
    },
    {
      id: 5,
      text: 'Activity',
      icon: 'activity',
      show: true,
      tabPanel: address && (
        <Activity_item activities={getWalletActivities} address={address} showChainFilter />
      ),
    },
    {
      id: 6,
      text: 'Favorites',
      icon: 'favorites',
      show: true,
      tabPanel: address && (
        // <FilterCategoryItem
        //   propertiesDisabled
        //   disableFilters
        //   additionalFilter={{ buy_now: true }}
        //   horizontalFilterEnabled
        //   fetchFavorites
        //   favoritesWalletAddress={address || walletAddress}
        // />
        <LikedItems walletAddress={address || walletAddress} />
      ),
    },
    {
      id: 7,
      text: 'Watchlist',
      icon: 'watchlist',
      show: true,
      tabPanel: <Explore_collection_item_table itemFor='userPage' collectionData={getWatchlist} />,
    },
    address === walletAddress && {
      id: 8,
      text: 'Bulk Tool',
      icon: 'bulktool',
      show: true,
      tabPanel: <BulkTool />,
    },
    address === walletAddress && {
      id: 9,
      text: 'Universal Wrapper',
      icon: 'universalwrap',
      show: true,
      tabPanel: <UniversalWrapper />,
    },
  ];
  const tabs = tabItem.filter((item) => item.show);

  const handleChange = (e, value) => {
    const query = qs.parse(window?.location?.search);
    const active = query?.active ? Number(query?.active) : 1;
    value = Number(value);
    if (active === value) return;
    query.active = value;
    if (tabs?.[value - 1]?.id !== 9) delete query?.tokenIds;
    setTab(value);
    const newQuery = qs.stringify(query);
    router.replace(`${window.location.pathname}?${newQuery}`, undefined, { shallow: true });
  };

  useEffect(() => {
    const active = router?.query?.active ? Number(router.query.active) : 1;
    if (active !== tab) {
      setTab(active);
    }
  }, [router]);

  return (
    <div id='tabs' className='container mt-[-64px]'>
      {/* <!-- Tabs Nav --> */}
      <div className='flex justify-center overflow-x-auto'>
        <Tabs
          value={tab}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
          classes={{
            flexContainer: 'flex flex-nowrap',
            root: 'h-16',
            indicator: '!bg-jacarta-700 dark:!bg-white',
          }}
        >
          {tabs.map(({ id, text, icon, onlyOwnWallet }, i) => {
            if (onlyOwnWallet && address !== walletAddress) return null;

            return (
              <Tab
                disableRipple
                key={`tabHeader-${id}`}
                data-testid='owned-nft-user-items'
                value={i + 1}
                label={text}
                className='font-display text-base font-medium normal-case'
                classes={{
                  labelIcon: '',
                  root: 'hover:text-jacarta-700 text-jacarta-400 dark:text-white',
                  selected: '!text-jacarta-700 dark:!text-white',
                }}
                icon={
                  icon === 'watchlist' ? (
                    <BsBookmarkHeart className='icon mr-1 mt-1 h-5 w-5 fill-current' />
                  ) : (
                    <svg className='icon mr-2 mt-1 h-5 w-5 fill-current'>
                      <use xlinkHref={`/icons.svg#icon-${icon}`} />
                    </svg>
                  )
                }
              />
            );
          })}
        </Tabs>
      </div>

      {tabs.map(({ id, tabPanel, onlyOwnWallet }, i) => {
        if (onlyOwnWallet && address !== walletAddress) return null;

        return (
          <TabPanel value={tab} index={i + 1} key={`tabPanel-${id}`}>
            {tabPanel}
          </TabPanel>
        );
      })}
    </div>
  );
};

export default User_items;
