import { constants } from '../helpers/constants';
import axios from '../lib/axios';
import useAdaptor from '../lib/swr';
import { useContext, useEffect, useState } from 'react';
import { DataRefreshContext } from '../components/refreshContext';
import { isAddress } from 'ethers/lib/utils.js';

export function useGetLayout(walletAddress) {
  const [enabled, setEnabled] = useState(false);
  const { refreshDate } = useContext(DataRefreshContext);

  useEffect(() => {
    if (!walletAddress) return setEnabled(false);
    setEnabled(isAddress(walletAddress));
  }, [walletAddress]);

  const config = {
    url: `${constants.api.url_new}/layout`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: constants.httpMethods.get,
    params: {
      walletAddress: String(walletAddress).toString(),
      refreshDate,
    },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return useAdaptor(enabled ? axios.getUri(config) : null, fetcher);
}
