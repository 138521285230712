import axios from '../../lib/axios';
import { constants } from '../../helpers/constants';
import useAdaptor from '../../lib/swr';
import { utils } from 'ethers';
import { useContext } from 'react';
import { DataRefreshContext } from '../../components/refreshContext';

export function getBulkGlobalBidsFetcher(
  collectionAddress,
  refreshDate,
  traitBids = false,
  rarityBids = false,
) {
  let config;

  const validAddresses = collectionAddress.filter((collectionAddress) =>
    utils.isAddress(collectionAddress),
  );

  if (validAddresses.length < 1) {
    config = {};
  } else {
    config = {
      url: `${constants.api.url_new}/orders/getBulkGlobalBids`,
      method: constants.httpMethods.post,
      data: { collectionAddress: validAddresses, refreshDate, traitBids, rarityBids },
    };
  }

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return { config, fetcher };
}

export default function useGetBulkGlobalBids(
  collectionAddress,
  traitBids = false,
  rarityBids = false,
) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, fetcher } = getBulkGlobalBidsFetcher(
    collectionAddress,
    refreshDate,
    traitBids,
    rarityBids,
  );
  return useAdaptor(axios.getUri(config), fetcher);
}
