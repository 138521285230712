import erc721ABI from '../../helpers/erc721ABI';
import { useCallback, useEffect, useState } from 'react';
import { useAccount, useWalletClient, useNetwork } from 'wagmi';
import { getContract, switchNetwork } from 'wagmi/actions';
import { getCdnUrl } from '../../lib/cdnUrl';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import { getSupportedChain } from '../../helpers/getSupportedChain';
import { useCatchTxError } from '../../hooks/useCatchTxError';

export const BulkToolCollections = ({
  collection,
  setApprovedCollections,
  selectedCollection,
  setSelectedCollection,
}) => {
  const { fetchWithCatchTxError } = useCatchTxError();
  const [image, setImage] = useState(null);
  const [approved, setApproved] = useState(false);
  const { address: walletAddress } = useAccount();
  const { data: walletClient } = useWalletClient({
    chainId: parseInt(collection?.chain),
  });
  const { chain } = useNetwork();
  const [disableButton, setDisableButton] = useState(false);
  const supportedChain = getSupportedChain(collection?.chain);
  const nftContract = getContract({
    address: collection?.address,
    abi: erc721ABI,
    walletClient,
    chainId: parseInt(collection?.chain),
  });

  const getApprove = useCallback(async () => {
    try {
      const isApprovedForAll = await nftContract?.read.isApprovedForAll([
        walletAddress,
        supportedChain?.marketplace,
      ]);

      setApproved(isApprovedForAll);
    } catch (error) {
      console.log(error);
    }
  }, [approved]);

  const toggleCollection = (collection) => {
    const collectionExists = selectedCollection.find((col) => col === collection?.address);

    if (collectionExists) {
      const newSelectedCollection = selectedCollection.filter((col) => col !== collection?.address);
      setSelectedCollection(newSelectedCollection);
    } else {
      setSelectedCollection([...selectedCollection, collection?.address]);
    }
  };

  const handleApprove = async () => {
    try {
      setDisableButton(true);
      if (chain?.hexId !== collection?.chain)
        await switchNetwork({
          chainId: parseInt(collection?.chain),
        });
      await fetchWithCatchTxError({
        callTx: () =>
          nftContract?.write?.setApprovalForAll?.([supportedChain?.marketplace, !approved]),
        chainId: parseInt(collection?.chain),
        toastMessage: `Collection Successfully ${approved ? 'Revoked' : 'Approved'}!`,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setDisableButton(false);
    }
  };

  useEffect(() => {
    setApprovedCollections((p) => {
      return { ...p, [collection?.address]: approved };
    });
  }, [approved]);

  useEffect(() => {
    setImage(getCdnUrl(collection?.profilePhotoPath, 'thumbnail', collection?.updatedAt));
    getApprove();
  }, [nftContract, walletClient]);

  return (
    <div>
      <div className='inline-flex w-full items-center justify-between gap-2'>
        <input
          type='checkbox'
          onChange={() => toggleCollection(collection)}
          checked={selectedCollection?.filter((f) => f === collection?.address).length > 0}
          //  checked={selectedCollection?.find((i) => i.id === collection.id)}
          className='h-6 w-6 cursor-pointer rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600'
          data-testid='bulkToolCollection'
        />
        <div className='min-h-[48px] min-w-[48px] rounded '>
          <ImageFixedAO image={image} alt={collection?.address} bottomRounded />
        </div>
        <div className='mr-auto flex w-fit flex-col justify-start gap-3 truncate'>
          <p className="-font-['Roboto'] truncate text-base font-bold">{collection?.name}</p>
          <div className="-font-['Roboto'] text-sm font-normal">
            {collection?._count.items > 1
              ? collection?._count.items + ' Items'
              : collection?._count.items + ' Item'}
          </div>
        </div>
        <div>
          <button
            onClick={handleApprove}
            className={`rounded-xl -font-['Roboto'] flex h-[40px] w-[108px] items-center bg-accent px-6 text-center text-base font-medium text-white hover:bg-accent-dark ${
              disableButton ? 'opacity-25' : ''
            } `}
            disabled={disableButton}
            data-testid='bulkToolapprove'
          >
            {approved ? 'Revoke' : 'Approve'}
          </button>
        </div>
      </div>
    </div>
  );
};
