import { ChainIcon } from '../Icon';

export const UWChains = ({ chain, disabled, selectedChainIds, setSelectedChainIds }) => {
  const isChecked = selectedChainIds.includes(chain.hexId);

  const handleCheckboxChange = () => {
    const updatedSelection = isChecked
      ? selectedChainIds.filter((id) => id !== chain.hexId)
      : [...selectedChainIds, chain.hexId];

    setSelectedChainIds(updatedSelection);
  };
  return (
    <div>
      <div key={chain.hexId} className='flex w-full items-center justify-start gap-2'>
        <input
          id={`chain_${chain.hexId}`}
          type='checkbox'
          name='chain_option'
          onChange={handleCheckboxChange}
          value={chain.hexId}
          checked={isChecked}
          disabled={disabled}
          className={[
            'rounded',
            'h-6 w-6',
            'text-accent',
            'focus:ring-accent/20 focus:ring-offset-0',
            'border-jacarta-200 dark:border-jacarta-500',
            disabled ? 'cursor-default' : 'cursor-pointer',
            disabled ? 'dark:bg-jacarta-500' : 'dark:bg-jacarta-600',
            disabled ? 'bg-jacarta-200' : 'bg-jacarta',
          ].join(' ')}
          data-testid='bulkToolChain'
        />
        <label
          className='flex flex-row items-center justify-center gap-1'
          htmlFor={`chain_${chain.hexId}`}
        >
          <div className='inline-flex cursor-pointer'>
            <ChainIcon width={17} name={chain.hexId} tooltip={null} type={'chain'} />
          </div>
          <div className='inline-flex cursor-pointer'>{chain.name}</div>
        </label>
      </div>
    </div>
  );
};
