import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export default function useGetEstimatedWalletValueByCollection(
  address = null,
  collectionAddress = null,
) {
  const { refreshDate } = useContext(DataRefreshContext);

  const config = {
    url: `${constants.api.url_new}/users/estimatedWalletValueByCollection`,
    method: constants.httpMethods.get,
    params: { walletAddress: address, collectionAddress, refreshDate },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  const isReady = address && collectionAddress;
  return useAdaptor(isReady ? axios.getUri(config) : null, fetcher);
}
