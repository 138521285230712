import { useEffect, useState } from 'react';

export const useInfiniteScroll = ({ useFetch, defaultLimit = 16, defaultPage = 1, ...rest }) => {
  const [page, setPage] = useState(defaultPage);
  const [limit, setLimit] = useState(defaultLimit);
  const [data, setData] = useState([]);

  const { data: res, isLoading, isError } = useFetch({ limit, page, ...rest });
  const { results, totalPages, totalCount } = res || {};
  const hasMore = page < totalPages && data.length < totalCount && !isLoading;

  useEffect(() => {
    if (results) {
      const startIndex = (page - 1) * limit;
      const endIndex = page * limit;
      const newData = data.slice(0, startIndex).concat(results).concat(data.slice(endIndex));
      setData(newData);
    }
  }, [results]);

  const loadMore = () => setPage((prev) => prev + 1);

  return {
    data,
    isLoading,
    isError,
    hasMore,
    loadMore,
  };
};

export const usePagination = ({ useFetch, defaultPage = 1, defaultLimit = 10, ...restArgs }) => {
  const [page, setPage] = useState(defaultPage);
  const [limit, setLimit] = useState(defaultLimit);
  const [data, setData] = useState([]);

  const { data: res, isLoading, isError } = useFetch({ limit, page: page + 1, ...restArgs });
  const { results, totalPages, totalCount } = res || {};
  const hasMore = page < totalPages && data.length < totalCount && !isLoading;

  useEffect(() => {
    if (results) {
      setData(results);
    }
  }, [results]);

  const nextPage = () => setPage((prev) => prev + 1);
  const prevPage = () => setPage((prev) => prev - 1);
  const changePage = (page) => {
    setPage(page);
  };

  const reset = () => {
    setData([]);
    setPage(defaultPage);
  };

  return {
    data,
    isLoading,
    isError,
    hasMore,
    totalCount,
    totalPages,
    page,
    reset,
    changePage,
    nextPage,
    prevPage,
  };
};
