import React from 'react';
import Link from 'next/link';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import Gravatar from 'react-gravatar';
import { TopCollectionsSkeleton } from '../Skelaton';
import { MD5 } from '../../lib/md5';
import useGetLikedItems from '../../hooks/useGetLikedItems';
import { getItemPath } from '../../helpers/path';
import { constants } from '../../helpers/constants';
import { useGetFavoriteItemsSingle } from '../../hooks/useGetFavoriteItems';
import { useAccount } from 'wagmi';
import Likes from '../likes';
import { useInfiniteScroll } from '../../hooks/usePagination';

export const LikedItems = ({ walletAddress, minLikes }) => {
  const { data, hasMore, isError, isLoading, loadMore } = useInfiniteScroll({
    useFetch: useGetLikedItems,
    defaultLimit: 16,
    defaultPage: 1,
    walletAddress,
    minLikes,
  });

  return (
    <section className='relative sm:pt-4'>
      <div>
        <div className='grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-4'>
          {data?.map((i) => {
            const {
              _count: { likes: likeCount },
              collection,
              ...item
            } = i;
            let image = '';
            if (collection?.isDNFT) {
              image = collection.thumbnailAPI + item?.itemId;
            } else {
              image = item?.imageFtpPath
                ? constants.cdn.path +
                  item?.imageFtpPath +
                  constants.cdn.thumbnail +
                  constants.cdn.version
                : item?.metadata?.image
                ? item?.metadata?.image
                : constants.helpers.noImage;
            }
            if (typeof item?.metadata === 'string') {
              item.metadata = JSON.parse(item?.metadata);
            }
            item.imageFtpPath = image;

            return (
              <MostLikedItemCard
                key={`${item?.collectionAddress}/${item?.itemId}`}
                item={item}
                collection={collection}
                likeCount={likeCount}
              />
            );
          })}
          {isLoading && <TopCollectionsSkeleton count={4} />}
        </div>
        {hasMore && (
          <div className='mt-4 flex items-center justify-center'>
            <button
              className={
                hasMore
                  ? 'rounded-md border border-accent py-2 px-4 text-sm font-semibold text-accent hover:bg-accent hover:text-white dark:border dark:hover:bg-accent dark:hover:text-white'
                  : 'rounded-md border border-jacarta-200 py-2 px-4 text-sm font-semibold text-jacarta-200 dark:border-jacarta-600 dark:text-jacarta-200'
              }
              onClick={loadMore}
              disabled={!hasMore}
            >
              Show More
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

const MostLikedItemCard = ({ item, collection, likeCount }) => {
  const { address } = useAccount();
  const isLiked = useGetFavoriteItemsSingle(
    address,
    item?.collectionAddress + '/' + item?.itemId,
  ).data;

  return (
    <div
      className='rounded-2xl relative flex border border-jacarta-100 bg-white p-3 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700'
      key={`${item?.collectionAddress}/${item?.itemId}`}
    >
      <figure className='relative mr-4 flex w-12 shrink-0 items-center'>
        <Link href={getItemPath(collection?.chain, item?.collectionAddress, item?.itemId)}>
          <a className='relative block w-full'>
            {item?.imageFtpPath ? (
              <ImageFixedAO
                bottomRounded
                image={item?.imageFtpPath}
                alt={`${collection?.pathName} #${item?.id}`}
              />
            ) : (
              <Gravatar email={MD5(collection?.address)} className='rounded-2lg' size={48} />
            )}
          </a>
        </Link>
      </figure>
      <div className='flex w-full flex-row items-center justify-between'>
        <Link href={getItemPath(collection?.chain, item?.collectionAddress, item?.itemId)}>
          <a className='block font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent'>
            {item?.metadata?.name || item?.name}
          </a>
        </Link>
        <div className='cursor-pointer'>
          <Likes
            isLiked={isLiked}
            item={item}
            alreadyLiked={isLiked}
            from={address}
            likeCount={likeCount}
            classes={'flex items-center space-x-1 dark:bg-jacarta-700'}
            md
          />
        </div>
        {/* <span className='flex items-center whitespace-nowrap rounded-md border-jacarta-100 py-1  dark:border-jacarta-600'>
          <div className='js-likes relative'>
            <svg className='icon icon-heart-fill h-4 w-4 fill-jacarta-500 group-hover/icon:fill-red dark:fill-jacarta-200 dark:group-hover/icon:fill-red'>
              <use xlinkHref='/icons.svg#icon-heart-fill'></use>
            </svg>
          </div>
          <span className='ml-1 text-jacarta-500 dark:text-jacarta-200'>{likeCount}</span>
        </span> */}
      </div>
    </div>
  );
};

{
  /* <div
className={
  'grid w-full grid-cols-2 gap-[0.875rem] p-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-8'
}
>
{data?.map((i, index) => {
  const {
    _count: { itemactivities: likeCount },
    collection,
    ...item
  } = i;

  return (
    <ItemCard
      // key={`CategoryItemIC/${item?.collectionAddress}/${item?.itemId}`}
      key={`CategoryItemIC/${collection?.address ?? index}/${item?.itemId ?? index}`}
      item={item}
      // itemOwner={item.ownerObject}
      likeCount={likeCount}
    />
  );
})}
{isLoading &&
  Array(10)
    .fill()
    .map((item, index) => {
      return <ItemCard key={`CategoryItemICsk` + index} item={{}} skeleton />;
    })}
</div> */
}
