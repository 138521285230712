import { useEffect, useState } from 'react';
import axios from '../lib/axios';
import { constants } from '../helpers/constants';

export default function useTraitFilterCount(offer, address) {
  const [traitFilterCount, setTraitFilterCount] = useState(null);
  const refreshTraitFilterCount = async () => {
    if (
      !offer.traitFilter ||
      //   !offer.hasEnoughBalance ||
      //   !offer.isApprovedEnough ||
      offer.issuer === address
    )
      return setTraitFilterCount(null);

    const { data: filterCount } = await axios.get(
      `${constants.api.url_new}/collections/availableNftsForTraitBid`,
      {
        params: {
          signature: offer.signature,
          walletAddress: address,
        },
      },
    );
    setTraitFilterCount(filterCount.data);
  };
  useEffect(() => {
    refreshTraitFilterCount();
  }, [offer]);
  return traitFilterCount;
}
