import { Tooltip } from '@mui/material';
import { useAccount } from 'wagmi';
import { setModal } from '../../redux/counterSlice';
import { useDispatch } from 'react-redux';
import { shortenAddress } from '../../helpers/address';
import { getTokenName } from '../../helpers/getChainName';
import { normalizeEther } from '../../helpers/digits';
import { getEnd } from '../../helpers/getEnd';
import { ChainIcon } from '../Icon';
import { ShowUser } from '../userImage';
import Link from 'next/link';
import useTraitFilterCount from '../../hooks/useTraitFilterCount';
import TableCollectionItem from '../TableCollectionItem';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { IoPencil } from 'react-icons/io5';

export default function User_orders_item({ order, received, address }) {
  const dispatch = useDispatch();
  //   console.log('order', order);
  const { address: walletAddress, isConnected } = useAccount();
  const {
    collection,
    item,
    signature,
    createdAt,
    end,
    chain,
    kind,
    issuer,
    status,
    price,
    eventType,
    globalBidAmount,
    filledGlobalBids,
    traitFilter,
    rarityFilter,
    id,
  } = order;
  const isGlobal = globalBidAmount > 0;
  const isTraitOffer = traitFilter && Object.keys(traitFilter).length > 0;
  const traitFilterCount = useTraitFilterCount(order, walletAddress);

  const columns = [
    {
      name: 'Type',
      // center: true,
      El: (
        <div className='ml-2 inline-flex'>
          <Tooltip
            title={eventType === 'bid' ? (globalBidAmount > 0 ? 'Global ' : '') + 'Bid' : 'Listing'}
            classes={{ tooltip: 'capitalize' }}
          >
            <span className='mx-auto'>
              <svg className='icon h-6 w-6 fill-jacarta-700 dark:fill-white'>
                <use xlinkHref={`/icons.svg#icon-${eventType}`} />
              </svg>
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      name: 'Item',
      El: <TableCollectionItem order={order} collection={collection} item={item} />,
    },
    {
      name: 'Price',
      El: (
        <div className={`flex ${isGlobal ? 'flex-col' : 'flex-row'}`}>
          <div className='flex items-center'>
            <ChainIcon name={chain} tooltip={getTokenName(chain)} sideGap={true} />
            <span className='leading-5'>{price ? normalizeEther(price) : '-'}</span>
          </div>
          {isGlobal && (
            <div className='flex items-center'>
              <span className='leading-5'>
                {filledGlobalBids || 0}/{globalBidAmount} filled
              </span>
              {/* <span className='leading-5'>
                {filledGlobalBids > 0
                  ? `${filledGlobalBids || 0}/${globalBidAmount} filled`
                  : `${globalBidAmount} available`}
              </span> */}
            </div>
          )}
        </div>
      ),
    },
    ...(received
      ? [
          {
            name: 'From',
            El: <ShowUser address={issuer} showName />,
          },
        ]
      : [
          {
            name: 'To',
            El: (
              <Link href={`/user/${item.owner}`}>
                <a>{shortenAddress(item.owner /*  ?? value.collectionAddress */) || '-'}</a>
              </Link>
            ),
          },
        ]),
    {
      name: 'Date',
      El: new Date(createdAt).toLocaleDateString('en-US'),
    },
    {
      name: 'Expires',
      El: getEnd(end),
    },
  ];

  return (
    <div className='flex' role='row'>
      {columns.map(({ El, center }, i) => (
        <div
          className={
            'flex items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600' +
            (center ? ' justify-center' : '')
          } // justify-center
          role='cell'
          key={`col-${i}`}
          style={{
            width: 95 / columns.length + '%',
          }}
        >
          <span className='ml-1 font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            {/* {El} */}
            {typeof El === 'function' ? <El /> : El}
          </span>
        </div>
      ))}
      <div
        className='mr-2 flex w-[8%] items-center border-t border-jacarta-100 py-4 dark:border-jacarta-600 lg:w-[5%]'
        role={'cell'}
      >
        {isConnected && issuer === walletAddress && (
          <>
            {eventType === 'bid' && (
              <div className='mr-2 pt-1'>
                <Tooltip title='Update Bid'>
                  <button
                    className=' text-accent hover:text-accent-light dark:text-jacarta-200 dark:hover:text-white'
                    onClick={() =>
                      dispatch(
                        setModal({
                          // key: 'cancelModal',
                          key: 'updateBidModal',
                          data: {
                            signature,
                            isGlobal,
                            kind,
                            chain,
                            other: item,
                            collection,
                            isGlobal,
                            id,
                            rarityFilter,
                          },
                        }),
                      )
                    }
                  >
                    <IoPencil size={20} />
                  </button>
                </Tooltip>
              </div>
            )}
            <Tooltip title='Cancel Order'>
              <button
                className=' text-accent hover:text-accent-light dark:text-jacarta-200 dark:hover:text-white'
                data-testid='orders-cancel'
                onClick={() =>
                  dispatch(
                    setModal({
                      // key: 'cancelModal',
                      key: eventType === 'listing' ? 'cancelSellModal' : 'cancelModal',
                      data: {
                        signature,
                        isGlobal,
                        kind,
                        chain,
                        other: item,
                        collection,
                        isGlobal,
                        id,
                      },
                    }),
                  )
                }
              >
                <RiDeleteBin2Line size={20} />
              </button>
            </Tooltip>
          </>
        )}
        {isConnected &&
          address === walletAddress &&
          issuer !== walletAddress &&
          (!isTraitOffer || traitFilterCount > 0) &&
          status === 'not processed' && (
            // !isChangedAfterListing &&
            // (isGlobalBid ? true : !isChangedAfterListing) &&
            /* (isGlobalBid ? true : !isChangedAfterBid) &&  */
            <button
              className='font-display text-sm font-semibold text-accent hover:text-accent-light dark:text-white'
              data-testid='received-offers-accept'
              onClick={() =>
                dispatch(
                  setModal({
                    key: 'acceptBidModal',
                    data: {
                      signature,
                      kind: 0,
                      chain,
                      item,
                      offer: order,
                      collectionInfo: collection,
                    },
                  }),
                )
              }
            >
              Accept
            </button>
          )}
      </div>
    </div>
  );
}
