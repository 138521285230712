import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useEffect, useState } from 'react';
import { isAddress } from 'ethers/lib/utils.js';

export default function useGetOwnedNfts({
  collectionAddresses,
  walletAddress,
  limit = 10,
  page = 1,
  search = '',
  buy_now,
  sort,
  selectedCollection,
  chainId,
}) {
  const config = {
    url: `${constants.api.url_new}/nfts/ownedNfts`,
    method: constants.httpMethods.get,
    params: {
      collectionAddresses,
      walletAddress,
      limit,
      page,
      search,
      buy_now,
      sort,
      selectedCollection,
      chainId,
    },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data;
    });

  const isReady = isAddress(walletAddress) && !collectionAddresses?.find((a) => !isAddress(a));
  return useAdaptor(isReady ? axios.getUri(config) : null, fetcher);
}
