import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext, useEffect, useState } from 'react';
import { DataRefreshContext } from '../components/refreshContext';
import { isAddress } from 'ethers/lib/utils.js';

export default function useGetWrappedCollections(address, chainId) {
  const [enabled, setEnabled] = useState(false);
  const { refreshDate } = useContext(DataRefreshContext);

  useEffect(() => {
    if (!address) return setEnabled(false);
    setEnabled(isAddress(address));
  }, [address]);

  const config = {
    url: `${constants.api.url_new}/collections/getOwnedWrappedCollections`,
    method: constants.httpMethods.get,
    params: { walletAddress: address, chainId: chainId, refreshDate },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return useAdaptor(enabled ? axios.getUri(config) : null, fetcher);
}
