import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useGetUser from '../../hooks/useGetUser';
import { TbLayoutDashboard } from 'react-icons/tb';
import { IoMdSettings } from 'react-icons/io';
import Gravatar from 'react-gravatar';
import Link from 'next/link';
import { useAccount } from 'wagmi';
import { MD5 } from '../../lib/md5';
import { useGetLayout } from '../../hooks/useGetLayout';
import Socials from '../Socials';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import { getCdnUrl } from '../../lib/cdnUrl';
import { Tooltip } from '@mui/material';
import useGetEstimatedWalletValue from '../../hooks/useGetEstimatedWalletValue';
import { ChainIcon } from '../Icon';
import { getTokenName } from '../../helpers/getChainName';
import { RiErrorWarningLine } from 'react-icons/ri';
import UserId from '../userId';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import User_items from './User_items';

const UserSection = () => {
  const router = useRouter();
  const address = router.query.address || router.query.user;
  const { address: walletAddress } = useAccount();
  const { user } = useGetUserInfo(address);
  const { data: userLayout } = useGetLayout(address);
  const { data: estimatedValue } = useGetEstimatedWalletValue(address);

  const [copied, setCopied] = useState(false);
  const [more, setMore] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  return (
    <div className='flex flex-col'>
      {/* <!-- Banner --> */}
      <div className='relative min-h-[75px] pb-[20%]'>
        <Image
          src={
            user?.coverPhoto
              ? getCdnUrl(user?.coverPhoto, 'w200', user?.updatedAt)
              : '/images/collections/_Bit5_U_Cover_1400x500.png'
          }
          alt='banner'
          layout='fill'
          objectFit='cover'
        />
      </div>
      {/* <!-- end banner --> */}
      {/* <section className=' px-4 pb-12  pt-28 '> */}
      <div
        className={[
          'flex w-full flex-col justify-between md:flex-row',
          'bg-gradient-to-t px-4 pt-14 pb-4',
          'dark:from-[#0e102dff] dark:via-[#0e102dff] dark:to-[#0e102d00]',
          'from-[#ffffffff] via-[#ffffffff] to-[#ffffff00]',
          'relative top-[-110px]',
          'border-b-[1px] border-b-gtr-base',
        ].join(' ')}
      >
        <div className='flex flex-row md:w-5/12'>
          <figure className='w-[170px] dark:border-jacarta-600'>
            {user?.profilePhoto ? (
              <ImageFixedAO
                image={getCdnUrl(user?.profilePhoto, 'thumbnail', user?.updatedAt)}
                bottomRounded
              />
            ) : (
              <Gravatar
                email={MD5(address)}
                size={150}
                className='rounded-xl !border-[5px] !border-solid !border-white bg-white dark:border-jacarta-600'
              />
            )}
            {user?.isVerified && (
              <div
                className='absolute -right-3 bottom-0 flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600'
                data-tippy-content='Verified Collection'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  className='h-[.875rem] w-[.875rem] fill-white'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' />
                </svg>
              </div>
            )}
          </figure>
          <div className='my-4 ml-2 flex flex-col justify-between'>
            <div className='flex flex-col items-start justify-start'>
              <h2 className='mb-2 font-display text-4xl font-medium text-jacarta-700 dark:text-white'>
                {user?.name}
              </h2>
              <UserId
                user={user}
                userId={address}
                shortId
                // classes='inline-flex w-fit items-center justify-center rounded-full border border-jacarta-100 bg-white py-1.5 px-4 dark:border-jacarta-600 dark:bg-jacarta-700'
                classes='hidden'
                showAddress
              />
            </div>
            {!router.query.user && address === walletAddress && (
              <div className='relative mt-5 flex flex-col items-start justify-start text-accent'>
                {/* <Link href={`/home/${address}`}> */}
                <span className='flex cursor-pointer items-center space-x-1'>
                  <IoMdSettings size={24} />
                  <a href={`/profile/${address}`} className='font-bold dark:text-white'>
                    Edit Profile
                  </a>
                </span>
                <span className='flex cursor-pointer items-center space-x-1'>
                  <TbLayoutDashboard size={24} />
                  <a href={`/home/${address}`} className='font-bold dark:text-white'>
                    Design your dashboard
                  </a>
                </span>
                {/* </Link> */}
              </div>
            )}
            {!router.query.user && userLayout && address !== walletAddress && (
              <div className='relative mt-5 flex text-accent'>
                <Link href={`/home/${address}`}>
                  <span className='flex cursor-pointer items-center space-x-1'>
                    <TbLayoutDashboard size={24} />
                    <a className='font-bold dark:text-white'>View user dashboard</a>
                  </span>
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className='flex flex-row items-end justify-end align-bottom md:w-6/12'>
          <div className='mt-4 flex flex-col'>
            <div className='flex w-full justify-between'>
              <span className='mt-5 text-jacarta-400'>
                {user && 'Joined ' + new Date(user?.createdAt).toUTCString()}
              </span>
              <div className='ml-2 flex'>
                <Socials data={user} />
              </div>
            </div>
            <div className='flex flex-row items-end justify-end'>
              <p
                className='mt-[6px] text-sm dark:text-jacarta-200'
                style={
                  !more
                    ? {
                        display: '-webkit-box',
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                        'line-clamp': '2',
                        '-webkit-line-clamp': '2',
                        '-webkit-box-orient': 'vertical',
                      }
                    : {
                        overflowY: 'auto',
                        maxHeight: '50px',
                      }
                }
              >
                {user?.bio}
              </p>
              {('' + user?.bio).length > 120 ? (
                <p
                  className={[
                    'h-fit cursor-pointer',
                    'font-display text-sm font-semibold text-accent hover:text-accent-light',
                    !more ? 'pb-0' : 'pb-[1px]',
                  ].join(' ')}
                  onClick={() => setMore(!more)}
                >
                  {!more ? <>More</> : <>Less</>}
                </p>
              ) : (
                <></>
              )}
            </div>

            {/* Estimated Wallet Value... */}
            <div className='w-full'>
              {estimatedValue && Object.keys(estimatedValue).length > 0 && (
                <div className='mt-2 flex w-full flex-row items-center justify-end'>
                  <div className='flex flex-row items-center justify-center'>
                    <Tooltip
                      title={
                        'Estimated value is calculated by multiplying the floor price of the collection by the number of items you own in the collection'
                      }
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                    >
                      <span className='ml-2 font-display text-sm font-semibold text-accent hover:text-accent-light dark:text-white'>
                        <RiErrorWarningLine size={18} />
                      </span>
                    </Tooltip>
                    <span className='ml-1 text-jacarta-400'>Estimated Wallet Value:</span>
                  </div>
                  <span className='ml-4 flex text-jacarta-400'>
                    {Object.keys(estimatedValue).map((key) => {
                      return (
                        <span key={key} className='mr-1 flex items-center'>
                          <ChainIcon sideGap name={key} size={16} tooltip={getTokenName(key)} />{' '}
                          <span>{Number(estimatedValue[key]?.toFixed(4))}</span>
                        </span>
                      );
                    })}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <User_items address={address} />
    </div>
  );
};

export default UserSection;

export const UserSectionSlim = () => {
  const router = useRouter();
  const { address: walletAddress } = useAccount();
  let address = router.query.address || router.query.user || walletAddress;

  const { data: user } = useGetUser(address);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  return (
    <>
      <div className='relative max-h-[250px] min-h-[115px] pb-[5%] md:pb-[20%]'>
        {
          <Image
            src={
              user?.coverPhoto
                ? getCdnUrl(user?.coverPhoto, 'w200')
                : '/images/collections/_Bit5_U_Cover_1400x500.png'
            }
            alt='banner'
            layout='fill'
            objectFit='cover'
          />
        }
        <div
          className='top-5 flex items-center justify-center md:absolute md:left-5 md:items-start md:justify-start'
          style={{ position: 'inherit' }}
        >
          <div className='relative'>
            {user?.isVerified && (
              <div
                className='absolute -right-3 -bottom-3 flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600'
                data-tippy-content='Verified Collection'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  className='h-[.875rem] w-[.875rem] fill-white'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' />
                </svg>
              </div>
            )}
          </div>

          <div className='ml-3'>
            {user?.name && (
              <h2 className='mt-1 mb-2 flex w-fit items-center rounded-2lg border border-jacarta-100 bg-jacarta-100 pl-1 pr-5 pb-1 font-display text-2xl font-medium text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'>
                <div className='mr-3 mt-[1px] ml-[2px] w-[50px]'>
                  {user?.profilePhoto ? (
                    <ImageFixedAO
                      image={getCdnUrl(user?.profilePhoto, 'thumbnail')}
                      bottomRounded
                    />
                  ) : (
                    <Gravatar
                      email={MD5(address)}
                      size={150}
                      className='rounded-xl !border-[5px] !border-solid !border-white bg-white dark:border-jacarta-600'
                    />
                  )}
                </div>
                {user?.name}
              </h2>
            )}
            <div className='inline-flex items-center justify-center rounded-full border border-jacarta-100 bg-white py-1.5 px-4 dark:border-jacarta-600 dark:bg-jacarta-700'>
              <Tooltip content={copied ? <span>copied</span> : <span>copy</span>}>
                <button className='js-copy-clipboard max-w-[10rem] select-none overflow-hidden text-ellipsis whitespace-nowrap dark:text-jacarta-200'>
                  <CopyToClipboard text={address} onCopy={() => setCopied(true)}>
                    <span>{address}</span>
                  </CopyToClipboard>
                </button>
              </Tooltip>
            </div>
            {user?.createdAt && (
              <div className='mt-2 text-jacarta-100'>
                {user && 'Joined ' + new Date(user?.createdAt).toUTCString().slice(0, 16)}
              </div>
            )}
          </div>
        </div>
        <div
          className='bottom-3 mt-5 md:!absolute md:right-5 md:mt-0'
          style={{ position: 'inherit' }}
        >
          <Socials data={user} />
        </div>
      </div>
    </>
  );
};
