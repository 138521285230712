import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export function useGetFavoriteItemsSingle(walletAddress, to, options) {
  const [enabled, setEnabled] = useState(false);
  const { refreshDate } = useContext(DataRefreshContext);

  useEffect(() => {
    if (!walletAddress || !to) return setEnabled(false);
    setEnabled(ethers.utils.isAddress(walletAddress));
  }, [walletAddress, to]);

  const config = {
    url: `${constants.api.url_new}/users/favoriteItems/single`,
    method: constants.httpMethods.get,
    params: {
      walletAddress,
      to,
      refreshDate,
    },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  const isReady = enabled && walletAddress && to;
  return useAdaptor(isReady ? axios.getUri(config) : null, fetcher);
}

export const useLikeCount = (to) => {
  const [enabled, setEnabled] = useState(false);
  let config;
  useEffect(() => {
    if (to) {
      setEnabled(true);
    }
  }, [to]);
  if (!enabled) {
    config = {};
  }
  config = {
    url: `${constants.api.url_new}/items/getLikeCountByItem`,
    method: constants.httpMethods.get,
    params: { to },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return useAdaptor(enabled ? axios.getUri(config) : null, fetcher);
};
