import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export default function useGetOrders({
  signature,
  chainId,
  sortBy = 'updatedAt:desc',
  limit = 20,
  page = 1,
  address,
  eventType = 'all',
  received,
}) {
  const { refreshDate } = useContext(DataRefreshContext);
  let config;
  // !signature
  // if (signature) {
  //     config = {};
  // } else {
  config = {
    url: `${constants.api.url_new}/orders`,
    method: constants.httpMethods.get,
    params: { sortBy, chainId, limit, page, address, signature, eventType, refreshDate, received },
  };
  // }

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data;
    });

  return useAdaptor(axios.getUri(config), fetcher);
}
