import Link from 'next/link';
import { ImageFixedAO } from './Image_fixed_aspect_ratio';
import { shortenAddress } from '../helpers/address';
import { getCollectionPath, getItemPath } from '../helpers/path';
import { getCdnUrl } from '../lib/cdnUrl';
import { useEffect, useState } from 'react';
import { constants } from '../helpers/constants';
import { formatErc404TokenId } from '../helpers/erc404';

export default function TableCollectionItem({ order, collection, item }) {
  const [image, setImage] = useState('');
  const isGlobal = order.globalBidAmount > 0;

  useEffect(() => {
    if (isGlobal)
      setImage(getCdnUrl(collection?.profilePhotoPath, 'thumbnail', collection?.updatedAt));
    else {
      const metadata = JSON.parse(item.metadata);
      let image;
      if (collection?.isDNFT) {
        image = collection.thumbnailAPI + item.itemId;
      } else {
        image = item?.imageFtpPath
          ? constants.cdn.path + item.imageFtpPath + constants.cdn.detail + constants.cdn.version
          : metadata?.image;
      }
      image = image.replace('w-540', 'w-270');
      setImage(image);
    }
  }, [order, item, collection]);

  return (
    <div className='flex items-center'>
      <figure className='relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12'>
        <ImageFixedAO
          image={
            isGlobal
              ? getCdnUrl(collection?.profilePhotoPath, 'thumbnail', collection?.updatedAt)
              : image
          }
          alt={item.collectionAddress}
          bottomRounded
        />
      </figure>
      <Link
        href={
          isGlobal
            ? getCollectionPath(collection?.chain, collection?.pathName)
            : getItemPath(item.chain, item.collectionAddress, item.itemId)
        }
      >
        <a className='space-y-1'>
          <span className='font-display text-sm font-semibold text-accent hover:text-accent-light dark:text-white'>
            {shortenAddress(item.collectionAddress)}
          </span>
          {!isGlobal && (
            <span className='block text-xs font-semibold text-jacarta-700 dark:text-white'>
              {collection?.contractType == 'ERC404'
                ? collection?.itemIdPrefix
                  ? `#${formatErc404TokenId(item?.itemId, collection?.itemIdPrefix)}`
                  : ''
                : `#${item?.itemId ? item.itemId : ''}`}
            </span>
          )}
        </a>
      </Link>
    </div>
  );
}
