import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export default function useGetLikedItems(
  { limit, page, walletAddress = null, minLikes = null } = {
    limit: 16,
    page: 1,
  },
) {
  const { refreshDate } = useContext(DataRefreshContext);
  const config = {
    url: `${constants.api.url_new}/items/getLikedItems`,
    method: constants.httpMethods.get,
    params: { refreshDate, limit, page, walletAddress, minLikes },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data;
    });

  return useAdaptor(axios.getUri(config), fetcher);
}
