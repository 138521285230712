import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import { ethers } from 'ethers';

export default async function editUserProfile(data, signature, chainId) {
  const config = {
    url: `${constants.api.url_new}/users/update`,
    headers: {
      //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: constants.httpMethods.post,
    data: { data: JSON.parse(data), signature, chainId },
  };

  const res = (await axios(config)).data;

  return res;
}

export const updateWidgetAsHome = async (data) => {
  const config = {
    url: `${constants.api.url_new}/users/updateWidget`,
    headers: {
      //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: constants.httpMethods.post,
    data: data,
  };

  const res = (await axios(config)).data;

  return res;
};
