import React from 'react';

const Status = ({ status, setStatus }) => {
  return (
    <div className='flex items-start justify-start gap-6 self-stretch pt-6 pb-6 lg:gap-2 xl:gap-6'>
      <button
        className={`rounded-xl -font-['Roboto'] flex h-[40px] w-[108px] items-center justify-center gap-2.5 whitespace-nowrap bg-accent px-6 py-4 font-medium dark:hover:border-transparent dark:hover:bg-accent-dark   ${
          status == 'onSale'
            ? 'bg-accent text-white '
            : ' border-2 border-jacarta-100 bg-white hover:bg-accent-dark hover:text-white focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-700 dark:text-white'
        } `}
        onClick={() => setStatus('onSale')}
        data-testid='bulkToolOnsale'
      >
        On Sale
      </button>
      <button
        className={`rounded-xl -font-['Roboto'] flex h-[40px] items-center justify-center gap-2.5 whitespace-nowrap border-2 border-transparent bg-accent text-center font-medium dark:hover:border-transparent dark:hover:bg-accent-dark ${
          status == 'notOnSale'
            ? 'bg-accent text-white'
            : 'border-2 border-jacarta-100 bg-white hover:bg-accent-dark hover:text-white focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-700'
        }  px-6`}
        onClick={() => setStatus('notOnSale')}
        data-testid='bulkToolNotOnsale'
      >
        Not on Sale
      </button>
    </div>
  );
};

export default Status;
